export default [
  {
    key: "code",
    label: "field.code",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "name",
    label: "field.name",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
